import { EOfferCalcTypes } from 'shared/enums/offerCalcTypes.enum';
import { ISelectOption } from 'shared/models/select.model';
import { EUserGroup } from 'shared/enums/user/userGroup.enum';
import { IMockProductResultedOffer } from 'shared/models/product.model';

export const PRODUCT_OFFERS_PRICE_RESULT: Array<ISelectOption> = [
  {
    id: 'Price and vat',
    label: 'цена товара + НДС',
    value: [EOfferCalcTypes.Price, EOfferCalcTypes.Vat],
  },
  {
    id: 'Price',
    label: 'цена товара',
    value: [EOfferCalcTypes.Price],
  },
];

export const PRODUCT_OFFERS_PRICE_WITH_DELIVERY_RESULT: Array<ISelectOption> = [
  {
    id: 'Price and delivery and vat',
    label: 'цена товара + доставка + НДС',
    value: [EOfferCalcTypes.Price, EOfferCalcTypes.Delivery, EOfferCalcTypes.Vat],
  },
  {
    id: 'Price and delivery',
    label: 'цена товара + доставка',
    value: [EOfferCalcTypes.Price, EOfferCalcTypes.Delivery],
  },
];

export const isPermittedAddToCart = [EUserGroup.ClientMpErp, EUserGroup.ClientAdmin, EUserGroup.ClientMpSupervisor];

export const isPermittedAddToERPCart = [EUserGroup.ClientMpErp, EUserGroup.ClientAdmin, EUserGroup.ClientMpSupervisor, EUserGroup.ClientErp];

export const mockedSortedOffersItems: IMockProductResultedOffer[] = [
  {
    unit: 'шт',
    available: true,
    stock: 247,
  },
  {
    unit: 'шт',
    available: true,
    stock: 64,
  },
  {
    unit: 'шт',
    available: true,
    stock: 100,
  },
  {
    unit: 'шт',
    available: true,
    stock: 136,
  },
  {
    unit: 'шт',
    available: true,
    stock: 136,
  },
  {
    unit: 'шт',
    available: true,
    stock: 136,
  },
  {
    unit: 'шт',
    available: true,
    stock: 10,
  },
  {
    unit: 'шт',
    available: true,
    stock: 10,
  },
];
