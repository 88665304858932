<template>
  <div class="characteristics-wrapper">
    <ProductCharacteristic
      :characteristics="groups?.short?.items"
      :max-elements="maxDisplayedItems"
      big-value-font
      no-button
      wrap-value
      half-width-mobile
    />

    <template v-if="isCatalogAvailable">
      <div v-if="isShownAll">
        <div
          v-for="(item, key) in groups?.other"
          :key="key"
        >
          <p
            v-if="item?.items?.length"
            class="mm-headline-4 models-page__h4"
          >
            {{ item.name }}
          </p>

          <ProductCharacteristic
            v-if="item?.items?.length"
            :characteristics="item?.items"
            big-value-font
            no-button
            wrap-value
            half-width-mobile
          />
        </div>
      </div>

      <button
        v-if="overallCount > 10"
        class="btn btn-secondary"
        @click="isShownAll = !isShownAll"
      >
        {{ isShownAll ? 'Свернуть все характеристики' : 'Показать все характеристики' }}
      </button>
    </template>

    <MButton
      v-else
      :theme="MM.EButtonThemes.Secondary"
      full-width
      disabled
    >
      {{ btnText }}
    </MButton>
  </div>
</template>

<script lang="ts" setup>
import { IProductCharacteristicGroups } from 'shared/models/product.model';
import ProductCharacteristic from 'shared/components/ProductCharacteristic.vue';
import { MM } from 'types';
import { useCatalog } from 'composables/useCatalog';

const props = defineProps<{
  groups: IProductCharacteristicGroups;
}>();

const MAX_DISPLAYED_UNLOGGED_ITEMS = 4;
const MAX_DISPLAYED_ITEMS = 10;
const { isCatalogAvailable } = useCatalog();

const overallCount = computed(
  () => props.groups?.short?.items?.length + props.groups?.other?.reduce((acc, group) => acc + group?.items?.length, 0),
);

const maxDisplayedItems = computed(() => {
  if (!isCatalogAvailable.value) {
    return MAX_DISPLAYED_UNLOGGED_ITEMS;
  }

  return isShownAll.value ? undefined : MAX_DISPLAYED_ITEMS;
});

const btnText = computed(() => {
  const itemsToDisplay =
    props.groups.short.items.length >= MAX_DISPLAYED_UNLOGGED_ITEMS ? MAX_DISPLAYED_UNLOGGED_ITEMS : props.groups.short.items.length;

  return `Показано ${itemsToDisplay} из ${overallCount.value} характеристик`;
});

const isShownAll = ref(false);
</script>

<style lang="scss" scoped>
.characteristics-wrapper {
  .btn {
    margin-top: 28px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }

  :deep(.MButton) {
    margin-top: 28px;
  }

  .mm-headline-4 {
    margin-top: 20px;
    font-weight: 500;
  }
}

@media screen and (min-width: 768px) {
  .characteristics-wrapper {
    :deep(.mm-product-characteristic-wrapper) {
      justify-content: flex-start !important;

      &::before {
        width: calc(50% - 4px) !important;
      }
    }

    :deep(.mm-product-characteristic-wrapper__label-column) {
      width: 50%;
    }

    :deep(.mm-product-characteristic-wrapper__value-column) {
      flex-basis: 50% !important;
      align-items: flex-start !important;
    }

    :deep(.mm-product-characteristic-value) {
      padding-left: 0 !important;
    }

    :deep(.mm-product-characteristic-value) {
      text-align: left !important;
    }

    :deep(.mm-product-characteristic-mark) {
      text-align: left !important;
    }
  }
}
</style>
